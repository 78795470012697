.button {
  display: inline-block;
  padding: .375rem 1rem .625rem;
  border: 2px solid var(--brand-color);
  font-size: 1.125rem;
  font-weight: 800;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  background-color: var(--brand-color);
  border-radius: 2px;
  transition: all .3s ease;

  &--sw {
    padding: .75rem .5rem;
  }

  &--small {
    font-size: 1rem;

    span {
      font-size: 1.625rem;

      @media screen and (max-width: 414px) {
        font-size: 1.125rem;
      }

      @media screen and (max-width: 320px) {
        font-size: .9375rem;
      }
    }
  }

  @media screen and (max-width: 414px) {
    padding: .375rem .625rem .625rem;
    font-size: .875rem;

    &--small {
      font-size: .75rem;
    }
  }

  @media screen and (max-width: 320px) {
    padding: .375rem .375rem .625rem;
    font-size: .75rem;

    &--small {
      font-size: .625rem;
    }
  }

  span {
    font-size: 1.75rem;

    @media screen and (max-width: 414px) {
      font-size: 1.25rem;
    }

    @media screen and (max-width: 320px) {
      font-size: 1rem;
    }
  }

  &:hover,
  &:focus {
    color: var(--brand-color);
    background-color: transparent;
  }
}

