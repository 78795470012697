.header {

  &__inner {
    position: relative;
    padding: 2.5rem 0;
    text-align: center;
  }

  &__logo {
    display: inline-block;

    &-img {
      max-width: 100%;
      height: auto;
    }
  }

  &__lang {
    position: absolute;
    top: 3.25rem;
    right: 0;

    @media screen and (max-width: 414px) {
      position: static;
      margin-bottom: 1rem;
      margin-left: -1rem;
    }

    &-item {
      display: inline-block;
      margin-left: 1rem;
      color: var(--primary-color);
      text-decoration: none;
      transition: color .3s ease;

      &--active,
      &:hover,
      &:focus {
        color: #9cd74e;
      }
    }
  }
}
