.footer {
  background-color: #2b2b2b;

  &__inner {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 1.5rem 0 1.25rem;

    @media screen and (max-width: 414px) {
      flex-flow: column nowrap;
      align-items: flex-start;
    }
  }

  &__logo {
    display: inline-block;
    width: 8rem;

    @media screen and (max-width: 414px) {
      margin-bottom: .5rem;
    }

    &-img {
      max-width: 100%;
      height: auto;
    }
  }

  &__link {
    margin-left: 2.625rem;
    color: #ffffff;
    transition: color .3s ease;

    @media screen and (max-width: 414px) {
      margin-left: 0;
    }

    &:hover,
    &:focus {
      color: var(--brand-color);
    }
  }
}
