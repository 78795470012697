.banner {
  width: 17rem;
  margin-left: 1rem;

  @media screen and (min-width: 823px) and (max-width: 1024px) {
    width: 15rem;
  }

  @media screen and (max-width: 823px) {
    width: auto;
    margin-left: 0 ;
  }

  &__inner {
    position: sticky;
    top: 2.25rem;
    margin-bottom: 2.25rem;
    border: 2px solid var(--primary-color);

    @media screen and (max-width: 823px) {
      position: static;
    }
  }

  &__pic {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    height: 14.875rem;
    font-size: 6.25rem;
    font-weight: 800;
    color: var(--brand-color);
    text-decoration: none;
    background: url("../images/banner-block.jpg") no-repeat 50% 50%;
    transition: color .3s ease;

    @media screen and (max-width: 823px) {
      background: url("../images/banner-block-mobile.jpg") no-repeat 100% 50%;
    }

    &--sw {
      height: 15.5rem;
      background: url("../images/sw-banner-block.jpg") no-repeat 50% 50%;
      text-shadow: -0 -3px 0 #ffffff, 0 -3px 0 #ffffff, -0 3px 0 #ffffff, 0 3px 0 #ffffff, -3px -0 0 #ffffff, 3px -0 0 #ffffff, -3px 0 0 #ffffff, 3px  0   0 #ffffff, -1px -3px 0 #ffffff, 1px -3px 0 #ffffff, -1px  3px 0 #ffffff, 1px  3px 0 #ffffff, -3px -1px 0 #ffffff, 3px -1px 0 #ffffff, -3px  1px 0 #ffffff, 3px  1px 0 #ffffff, -2px -3px 0 #ffffff, 2px -3px 0 #ffffff, -2px  3px 0 #ffffff, 2px  3px 0 #ffffff, -3px -2px 0 #ffffff, 3px -2px 0 #ffffff, -3px  2px 0 #ffffff, 3px  2px 0 #ffffff, -3px -3px 0 #ffffff, 3px -3px 0 #ffffff, -3px  3px 0 #ffffff, 3px  3px 0 #ffffff, -3px -3px 0 #ffffff, 3px -3px 0 #ffffff, -3px  3px 0 #ffffff, 3px  3px 0 #ffffff;

      @media screen and (max-width: 823px) {
        background: url("../images/sw-banner-block-mobile.jpg") no-repeat 100% 50%;
      }
    }

    &:hover,
    &:focus {
      color: #f03e24;
    }
  }

  &__text {
    display: block;
    padding: 1.375rem .5rem 1rem;
    font-size: 1.5rem;
    font-weight: 800;
    color: #ffffff;
    line-height: 1.083333;
    text-align: center;
    text-decoration: none;
    background-color: var(--brand-color);

    &--small {
      font-size: 1.375rem;
    }
  }

  &__comment {
    padding: 1.125rem 1rem 3.25rem 1.5rem;

    @media screen and (max-width: 823px) {
      display: none;
    }

    &-stars {
      margin-bottom: .75rem;
    }

    &-star {
      display: inline-block;
      width: 1.125rem;
      height: 1rem;
      background: url("../images/star.png") no-repeat 50% 50%;
    }

    &-name {
      font-size: .9375rem;
    }

    &-date {
      margin-bottom: .75rem;
      font-size: .8125rem;
      color: var(--secondary-color);
    }

    &-text {
      margin-top: 0;
      margin-bottom: .25rem;
      font-size: .875rem;
    }

    &-link {
      display: inline-block;
      margin-top: 1rem;
      font-size: .875rem;
      font-weight: bold;
      color: var(--brand-color);
    }
  }


}

.content {
  flex: 1;

  &__banner {
    display: block;
    margin-bottom: 2.625rem;

    &-img {
      max-width: 100%;
      height: auto;
      transition: opacity .3s ease;

      &:hover,
      &:focus {
        opacity: .85;
      }
    }
  }

  &__autor {
    position: relative;
    margin-bottom: 2.25rem;
    padding-bottom: .875em;

    &-item {
      margin-top: 0;
      margin-bottom: .5rem;
      font-size: .9375rem;
      font-weight: bold;

      &--date {
        font-size: .875rem;
        font-weight: normal;
        color: var(--secondary-color);
      }
    }

    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 5.125rem;
      height: 2px;
      background-color: var(--primary-color);
      content: '';
    }
  }

  &__double {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-bottom: 2.5rem;

    &--sw {
      margin-top: 2.5rem;
    }

    @media screen and (max-width: 823px) {
      flex-flow: column nowrap;
    }

    &-item {
      width: calc(50% - .8rem);

      @media screen and (min-width: 823px) and (max-width: 1024px) {
        width: calc(50% - .4rem);
      }

      @media screen and (max-width: 823px) {
        width: auto;
      }

      &:first-child {
        @media screen and (max-width: 823px) {
          margin-bottom: 2rem;
        }
      }
    }

    &-img {
      max-width: 100%;
      height: auto;
      transition: opacity .3s ease;

      @media screen and (max-width: 823px) {
        display: block;
        margin-left: auto;
        margin-right: auto;
      }

      &:hover,
      &:focus {
        opacity: .85;
      }
    }
  }

  &__banner-low {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    height: 7.5rem;
    padding-left: 2.25rem;
    margin-bottom: 2.375rem;
    background: url('../images/banner-low.jpg') no-repeat 50% 50%;

    &--sw {
      justify-content: flex-end;
      padding-right: 2.25rem;
      background: url('../images/sw-banner-low.jpg') no-repeat 50% 50%;
    }

    @media screen and (max-width: 414px) {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  &__banner-high {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    height: 20.5rem;
    margin-top: 2.5rem;
    padding-left: 2.25rem;
    margin-bottom: 2.375rem;
    background: url('../images/banner-high.jpg') no-repeat 50% 50%;

    &--sw {
      margin-top: 1.5rem;
      background: url('../images/sw-banner-high.jpg') no-repeat 50% 50%;
    }

    @media screen and (max-width: 414px) {
      padding-left: 1rem;
      padding-right: 1rem;
    }

    &-wrapper {
      text-align: center;
    }

    &-slogan {
      margin-bottom: 3.25rem;
      font-size: 2.5rem;
      font-weight: 800;
      color: #ffffff;
      line-height: 1;

      &--sw {
        margin-bottom: 1rem;
      }

      @media screen and (max-width: 414px) {
        font-size: 2rem;
      }
    }
  }
}

.comments {
  margin-bottom: 2.75rem;

  &__inner {
    margin-top: -1rem;
    padding-top: 1.5rem;
    border-top: 2px solid var(--primary-color);
  }

  &__item {
    position: relative;
    margin-bottom: .75rem;
    padding-bottom: 1rem;

    &:after {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 4rem;
      height: 1px;
      background-color: var(--secondary-color);
      content: '';
    }

    &-name {
      font-size: .9375rem;

      b {
        display: inline-block;
      }
    }

    &-date {
      font-size: .8125rem;
      color: var(--secondary-color);
    }

    &-text {
      margin-top: 0;
      margin-bottom: 0;
      font-size: .875rem;
    }

    &-stars {
      display: inline-block;
      margin-left: .875rem;
    }

    &-star {
      display: inline-block;
      width: 1.125rem;
      height: 1rem;
      background: url("../images/star.png") no-repeat 50% 50%;

      &--grey {
        background: url("../images/star-grey.png") no-repeat 50% 50%;
      }
    }
  }

  &__link {
    font-size: .875rem;
    color: var(--brand-color);
  }
}

.emotional {
  margin-top: 1.5rem;
  padding: 1.125rem 1rem 1.25rem;
  font-size: 1.125rem;
  font-weight: 700;
  text-align: center;
  background-color: #ffcc00;

  &--transparent {
    margin-top: 0;
    padding: 0 1rem 1.25rem;
    background-color: transparent;
  }
}
