html {
  scroll-behavior: smooth;
}

body {
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-height: 100vh;
}

.main {
  flex: 1 0 auto;

  &__inner {
    display: flex;
    flex-flow: row-reverse nowrap;

    @media screen and (max-width: 823px) {
      flex-flow: column nowrap;
    }
  }
}
