.title {
    margin-top: 2.5rem;
    margin-bottom: 2.125rem;
    font-size: 2rem;
    line-height: 1.1875;

    @media screen and (max-width: 414px) {
        font-size: 1.625rem;
    }

    &--bold {
        font-weight: 800;
    }

    &--small {
        font-size: 1.875rem;

        @media screen and (max-width: 414px) {
            font-size: 1.5rem;
        }

    }

    &--xsmall {
        margin-bottom: .75rem;
        font-size: 1.5rem;
    }
}

.paragraph {
    margin-top: 0;
    margin-bottom: .75rem;
    font-size: 1.125rem;
    line-height: 1.333333;

    &--small {
        font-size: 1rem;
    }

    &--red {
        margin: 2rem 0;
        color: #f03e24;
    }

    &--sw-red {
        margin-bottom: 1.5rem;
        font-size: 1rem;
        line-height: 1.75;
        color: #f03e24;
    }

    a {
        color: var(--brand-color);
        text-decoration: none;

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }
}
